// Global settings

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Color system

$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default; // Line footer color
$gray-700: #f6f9fc !default; // Line p color
//$gray-700: #525f7f !default; // Line p color //Original
$gray-800: #32325d !default; // Line heading color
$gray-900: #212529 !default;
$link-disabled: #666666 !default;
$transparent-bg: transparent !default;
$light-bg: #f5f6fa !default;
$dark-background: #555555 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #f5365c !default;
$orange: #fb6340 !default;
$yellow: #ffd600 !default;
$green: #2dce89 !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$line-height-lg: 1.625rem !default;
$line-height-sm: 1.5 !default;
$line-height: 1.35em !default;

$opacity-gray-3: rgba(222, 222, 222, 0.3) !default;
$opacity-gray-5: rgba(222, 222, 222, 0.5) !default;
$opacity-gray-8: rgba(222, 222, 222, 0.8) !default;

$opacity-5: rgba(255, 255, 255, 0.5) !default;
$opacity-6: rgba(255, 255, 255, 0.6) !default;
$opacity-8: rgba(255, 255, 255, 0.8) !default;

$opacity-1: rgba(255, 255, 255, 0.1) !default;
$opacity-2: rgba(255, 255, 255, 0.2) !default;

// end beniamin

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "light": $gray-400,
    "lighter": $gray-200,
    "gray-dark": $gray-800
  ),
  $colors
);

$default-color-opacity: rgba(182, 182, 182, 0.6) !default;
$orange-color: #f96332 !default;

$default: #304780 !default;
$primary: #e14eca !default;
$secondary: #f4f5f7 !default;
$success: #00f2c3 !default;
$info: #4f74d1  !default;
// $warning: #ff8d72 !default;//original
$warning: #FC750D !default;
$danger: #FC750D !default;
// $danger: #fd5d93 !default; //original
$black: #222a42 !default;

// gradient
$default-states: #263148 !default;
$primary-states: #ba54f5 !default;
$success-states: #0098f0 !default;
$info-states: #3358f4 !default;
$warning-states: #ff6491 !default;
$danger-states: #ec250d !default;
$black-states: #1d253b !default;

$background-black: #474747 !default;
// $background-black: #171941 !default;//original
$background-states-black: #1e1e24 !default;

// opacity
$default-opacity: rgba(182, 182, 182, 0.6) !default;
$primary-opacity: rgba(249, 99, 50, 0.3) !default;
$success-opacity: rgba(24, 206, 15, 0.3) !default;
$info-opacity: rgba(44, 168, 255, 0.3) !default;
$warning-opacity: rgba(255, 178, 54, 0.3) !default;
$danger-opacity: rgba(255, 54, 54, 0.3) !default;

$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
$card-stats-gray: #808080 !default;
$active-gray: #777777 !default;
$nav-gray: #444444 !default;
$search-gray: #b7b7b7 !default;
$calendar-gray: #9499a7 !default;
$active-blue: #00bbff !default;

$light: $gray-500 !default;
$dark: $gray-900 !default;
$darker: darken($gray-900, 15%) !default;

$facebook: #3b5998 !default;
$facebook-states: darken($facebook, 5%) !default;
$twitter: #55acee !default;
$twitter-states: darken($twitter, 5%) !default;
$google-plus: #dd4b39 !default;
$google-plus-states: darken($google-plus, 5%) !default;
$instagram: #125688 !default;
$instagram-states: lighten($instagram, 6%) !default;
$linkedin: #0077b5 !default;
$linkedin-states: darken($linkedin, 5%) !default;
$pinterest: #cc2127 !default;
$pinterest-states: lighten($pinterest, 6%) !default;
$youtube: #e52d27 !default;
$youtube-states: lighten($youtube, 6%) !default;
$dribbble: #ea4c89 !default;
$dribbble-states: lighten($dribbble, 6%) !default;
$github: #333333 !default;
$github-states: #ccc !default;
$reddit: #ff4500 !default;
$reddit-states: lighten($reddit, 6%) !default;
$tumblr: #35465c !default;
$tumblr-states: lighten($tumblr, 6%) !default;
$behance: #1769ff !default;
$behance-states: lighten($behance, 6%) !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "default": $default,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "neutral": $white,
    "dark": $dark,
    "darker": $darker
  ),
  $theme-colors
);

$brand-colors: () !default;
$brand-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "google-plus": $google-plus,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "youtube": $youtube,
    "dribbble": $dribbble,
    "github": $github,
    "reddit": $reddit,
    "behance": $behance,
    "tumblr": $tumblr,
    "linkedin": $linkedin
  ),
  $brand-colors
);

/*     brand Colors              */
$brand-primary: $primary !default;
$brand-info: $info !default;
$brand-success: $success !default;
$brand-warning: $warning !default;
$brand-danger: $danger !default;
$brand-inverse: $black !default;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Action colors

$star-rating-color: $gray-400 !default;
$star-rating-color-active: $yellow !default;

$favorite-color: $yellow !default;
$like-color: $blue !default;
$love-color: $red !default;

// Body

$body-bg: $background-black !default;
$body-color: $gray-700 !default;

// Sections

$section-colors: () !default;
$section-colors: map-merge(
  (
    "primary": $body-bg,
    "secondary": $secondary,
    "light": $gray-400,
    "dark": $dark,
    "darker": $darker
  ),
  $section-colors
);

// Links

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none !default;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// Spacing

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    ),
    "sm": (
      $spacer * 2
    ),
    "md": (
      $spacer * 4
    ),
    "lg": (
      $spacer * 6
    ),
    "xl": (
      $spacer * 8
    )
  ),
  $spacers
);

$padding-base-vertical: 0.5rem !default;
$padding-base-horizontal: 0.7rem !default;

$padding-btn-vertical: 11px !default;
$padding-btn-horizontal: 40px !default;

$padding-round-horizontal: 23px !default;

$padding-lg-vertical: 15px !default;

$padding-input-vertical: 11px !default;
$padding-input-horizontal: 19px !default;

$margin-lg-vertical: 30px !default;
$margin-base-vertical: 15px !default;

// This variable affects the `.h-*` and `.w-*` classes.

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
  ),
  $sizes
);

// Components

$shape-height-xl: 1.5 !default;
$shape-height-lg: 1.5 !default;
$shape-height-sm: 1.5 !default;

// border
$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;
$border-width: 0.0625rem !default;
$border-color: $gray-200 !default;

$border-radius: 0.25rem !default;
$border-radius-xl: 0.875rem !default;
$border-radius-lg: 0.4285rem !default;
$border-radius-sm: 0.2857rem !default;
$border-radius-xs: 0.1428rem !default;

$margin-bottom: 10px !default;
$border: 1px solid !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
$box-shadow-lg: 2px 2px 6px rgba(0, 0, 0, 0.4);

$dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
$sidebar-box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2),
  0px 2px 30px 0 rgba(0, 0, 0, 0.35);

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;
$component-active-border-color: theme-color("primary") !default;

$component-hover-color: $gray-300 !default;
$component-hover-bg: $gray-300 !default;
$component-hover-border-color: $gray-300 !default;

$caret-width: 0.3em !default;

$transition-base: all 0.15s ease !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$fast-transition-time: 150ms !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$select-coordinates: 50% -40px !default;
$general-transition-time: 300ms !default;

// Fonts

$font-family-base: "Poppins", sans-serif !default;
$font-family-alt: "Poppins", sans-serif !default;

$font-size-dash: 0.875rem !default;

$font-size-base: $font-size-dash !default; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.428);
$font-size-lg: ($font-size-base * 1.142);
$font-size-sm: ($font-size-base * 0.85714286);
$font-size-xs: ($font-size-base * 0.714);

$h1-font-size: $font-size-base * 2.44444 !default; //2.1875rem //2.35714285
$h2-font-size: $font-size-base * 1.9285714 !default;
$h3-font-size: $font-size-base * 1.642857 !default;
$h4-font-size: $font-size-base * 1.2142857 !default;
$h5-font-size: $font-size-base * 0.9285714 !default;
$h6-font-size: $font-size-base * 0.8571428 !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-paragraph: 1rem !default;

$font-weight-base: $font-weight-normal !default;
$shape-height-base: 1.428571 !default;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.2 !default;
$headings-color: $gray-800 !default;

$heading-letter-spacing: 0.025em !default;
$heading-font-size: 0.95rem !default;
$heading-text-transform: uppercase !default;
$heading-font-weight: $headings-font-weight !default;

$heading-title-letter-spacing: 0.025em !default;
$heading-title-font-size: 1.375rem !default;
$heading-title-font-weight: $font-weight-bold !default;
$heading-title-text-transform: uppercase !default;

$heading-section-letter-spacing: 0.025em !default;
$heading-section-font-size: 1.375rem !default;
$heading-section-font-weight: $font-weight-bold !default;
$heading-section-text-transform: uppercase !default;

$display1-size: 3.3rem !default;
$display2-size: 2.75rem !default;
$display3-size: 2.1875rem !default;
$display4-size: 1.6275rem !default;

$display1-weight: $font-weight-bold !default;
$display2-weight: $font-weight-bold !default;
$display3-weight: $font-weight-bold !default;
$display4-weight: $font-weight-bold !default;
$display-line-height: $headings-line-height !default;

$paragraph-font-size: 0.625rem !default;
$paragraph-font-weight: 300 !default;
$paragraph-line-height: 1.7 !default;

$lead-font-size: ($paragraph-font-size * 1.25);
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.1);

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer * 2 !default;

// Icons

$icon-size: auto !default;
$icon-size-regular: 2.375rem !default;
$icon-font-size-regular: 0.9375rem !default;
$icon-font-size-sm: 0.6875rem !default;
$icon-size-sm: 1.875rem !default;
$icon-font-size-lg: 1.325rem !default;
$icon-size-lg: 3.7rem !default;
$icon-size-xl: 5rem !default;

// Tables

$table-cell-padding: 1rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05);
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-300 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $body-bg !default;

// Buttons + Forms

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.7rem !default;
$input-btn-line-height: $shape-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: rgba($component-active-bg, 1);
//$input-btn-focus-box-shadow:  0 4px 10px 0 rgba(0, 0, 0, 0.04);
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: 1.35 !default;

$input-btn-padding-y-lg: 0.875rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: 1.35 !default;

$input-btn-border-width: 1px !default;

$input-btn-font-size-sm: 0.75rem !default;
$input-btn-font-size: 0.875rem !default;
$input-btn-font-size-lg: 0.875rem !default;

$input-padding-vertical: 11px !default;
$input-padding-horizontal: 19px !default;

// Buttons

$btn-padding-y: 11px !default;
$btn-padding-x: 40px !default;
$btn-line-height: $line-height !default;

$btn-padding-y-sm: 5px !default;
$btn-padding-x-sm: 15px !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: 15px !default;
$btn-padding-x-lg: 48px !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-size-lg: 1em !default;
$btn-font-weight: 400 !default;
$btn-text-transform: uppercase !default;
$btn-letter-spacing: 0.025em !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-focus-width: $input-btn-focus-width !default;

$btn-active-box-shadow: none !default;
$btn-round-radius: 30px !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-border-radius: $border-radius !default;
$input-border-radius-xl: $border-radius-xl !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-bg: $transparent-bg !default;
$input-disabled-bg: $gray-200 !default;

$input-muted-bg: #edf0f5 !default;

$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
  0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$input-color: $opacity-8 !default;
$input-border-color: #cad1d7 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: none !default; // 0 1px 3px 0 $gray-400 !default;

$input-focus-bg: $white !default;
$input-focus-border-color: rgba(50, 151, 211, 0.25);
$input-focus-color: $input-color !default;
$input-focus-width: 0 !default;
$input-focus-box-shadow: none !default; //0 1px 3px 0 $gray-500 !default;

$input-placeholder-color: $gray-500 !default;
$input-focus-placeholder-color: $gray-500 !default;

$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Input groups

$input-group-addon-color: $input-placeholder-color !default;
$input-group-addon-bg: $input-bg !default;
$input-group-addon-border-color: $input-border-color !default;

$input-group-addon-focus-color: $input-focus-color !default;
$input-group-addon-focus-bg: $input-focus-bg !default;
$input-group-addon-focus-border-color: $input-focus-border-color !default;

// Custom forms

$custom-control-gutter: 1.75rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-indicator-size: 1.25rem !default;

$custom-control-indicator-bg: $input-bg !default;
$custom-control-indicator-border-width: 1px !default;
$custom-control-indicator-border-color: $input-border-color !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-hover-color: $component-hover-color !default;
$custom-control-indicator-hover-bg: $component-hover-bg !default;
$custom-control-indicator-hover-border-color: $component-hover-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: $component-active-bg !default;
$custom-control-indicator-active-border-color: $component-active-border-color !default;
$custom-control-indicator-active-box-shadow: $custom-control-indicator-box-shadow !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-border-color: $component-active-border-color !default;
$custom-control-indicator-checked-box-shadow: $custom-control-indicator-box-shadow !default;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
);

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-checkbox-indicator-border-radius: $border-radius-sm !default;
//$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-toggle-slider-bg: #ddd !default;
$custom-toggle-checked-bg: theme-color("primary") !default;

// Form validation

$form-feedback-valid-bg: lighten($success, 15%);
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-bg: lighten($warning, 15%);
$form-feedback-invalid-color: theme-color("warning") !default;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: $input-border-radius !default;
$btn-border-radius-xl: $input-border-radius-xl !default;
$btn-border-radius-lg: $input-border-radius-lg !default;
$btn-border-radius-sm: $input-border-radius !default;

// No UI Slider

$noui-target-bg: #eceeef !default;
$noui-target-thickness: 5px !default;
$noui-target-border-radius: 5px !default;
$noui-target-border-color: 0 !default;
$noui-target-box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);

$noui-slider-connect-bg: $primary !default;
$noui-slider-connect-disabled-bg: #b2b2b2 !default;

$noui-handle-width: 15px !default;
$noui-handle-bg: theme-color("primary") !default;
$noui-handle-border: 0 !default;
$noui-handle-border-radius: 100% !default;

$noui-origin-border-radius: 2px !default;

// Dropdown

$dropdown-bg: $white !default;
$dropdown-border-width: 0 !default;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius-xs !default;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
  0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-color: $gray-700 !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-pills-padding-y: 0.75rem !default;
$nav-pills-padding-x: 1rem !default;

$nav-pills-space-x: 1rem !default;

$nav-pills-bg: $white;
$nav-pills-border-width: 1px !default;
$nav-pills-border-color: theme-color("primary") !default;
$nav-pills-border-radius: $border-radius !default;

$nav-pills-link-color: theme-color("primary") !default;
$nav-pills-link-hover-color: darken(theme-color("primary"), 5%);
$nav-pills-link-active-color: color-yiq(theme-color("primary"));
$nav-pills-link-active-bg: theme-color("primary") !default;
$nav-pills-box-shadow: $btn-box-shadow !default;

// Navbar

$navbar-transition: all 0.15s linear !default;
$navbar-padding-y: 0.625rem !default;
$navbar-padding-x: 0.9375rem !default;

$navbar-nav-link-padding-x: 1rem !default;
$navbar-nav-link-padding-y: 1rem !default;

$navbar-nav-link-font-family: $font-family-alt !default;
$navbar-nav-link-font-size: 0.9rem !default;
$navbar-nav-link-font-weight: 400 !default;
$navbar-nav-link-text-transform: normal !default;
$navbar-nav-link-letter-spacing: 0 !default;
$navbar-nav-link-border-radius: $border-radius !default;

/* navbar color */
$navbar-color: #1a1e34 !default;
$navbar-dark-bg: transparent !default;
$navbar-dark-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-active-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-color: rgba($white, 0.95);
$navbar-dark-hover-color: rgba($white, 0.65);
$navbar-dark-active-color: rgba($white, 0.65);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: transparent !default;

$navbar-light-bg: transparent !default;
$navbar-light-hover-bg: rgba(0, 0, 0, 0.1);
$navbar-light-active-bg: rgba(0, 0, 0, 0.1);
$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: transparent !default;
$full-width: 100% !default;
$navbar-padding: 10px 15px !default;

// Alerts

$alert-padding-y: 0.9rem !default;
$alert-padding-x: 1.25rem !default;
$alert-border-radius: $border-radius-sm !default;

$alert-bg-level: -2 !default;
$alert-border-level: -2 !default;
$alert-color-level: 0 !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: $border-color !default; //rgba($black, .125);
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 1rem !default;
$list-group-item-padding-x: 1rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $list-group-action-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-bg: transparent !default;
$close-hover-bg: transparent !default;
$close-color: rgba(0, 0, 0, 0.6);
$close-hover-color: rgba(0, 0, 0, 0.9);
$close-text-shadow: none !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: 1px !default;
$popover-border-color: rgba($black, 0.05);
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0px 0.5rem 2rem 0px rgba($black, 0.2);

$popover-header-bg: $popover-bg !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.75rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1.5rem !default;
$popover-arrow-height: 0.75rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: transparent !default;

// Breadcrumbs

$breadcrumb-bg: $black-states !default;
$breadcrumb-divider-color: $white !default;
$breadcrumb-active-color: $white !default;

// Badges

$badge-font-size: $font-size-xs !default;
$badge-font-weight: $font-weight-extra-bold !default;
$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.5rem !default;
$badge-border-radius: $border-radius !default;
$badge-text-transfom: uppercase;

$badge-pill-padding-x: 0.875em !default;
$badge-pill-border-radius: $border-radius-xl !default;

$badge-circle-size: 2rem;

// Pagination

$pagination-padding-y: 0 !default;
$pagination-padding-x: 0.6875rem !default;

$pagination-color: $white !default;
$pagination-bg: transparent !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300 !default;

//$pagination-focus-box-shadow:       $btn-hover-box-shadow !default;

$pagination-hover-color: $gray-600 !default;
$pagination-hover-bg: $gray-300 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-active-box-shadow: $btn-hover-box-shadow !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: $gray-300 !default;

// Cards

$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.5rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-sm !default;
$card-border-color: rgba($black, 0.05);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-100 !default;
$card-bg: $white !default;
$card-black-background: #2f2f2f !default;
// $card-black-background: #1f2251 !default;//original
$card-img-overlay-padding: 1.25rem !default;

//$card-group-margin:                 ($grid-gutter-width / 2);
//$card-deck-margin:                  $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $black !default;
$tooltip-bg: $white !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Modals

$modal-inner-padding: 24px 24px 16px 24px !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 380px !default;
$modal-title-line-height: 1.1;
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 1px !default;
$modal-content-border-radius: $border-radius-sm !default;
$modal-content-box-shadow-xs: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
$modal-content-box-shadow-sm-up: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.16 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 24px 24px 0 24px !default;

// Progress bars

$progress-height: 0.5rem !default;
$progress-bg: rgba(0, 0, 0, 0.3) !default;
$progress-border-radius: $border-radius-xl !default;
$progress-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3) !default;
$progress-bar-color: $white !default;

// Datepicker

$datepicker-border-radius: $card-border-radius !default;
$datepicker-dropdown-padding: 20px 22px !default;

$datepicker-cell-transition: $transition-base !default;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50% !default;
$datepicker-cell-width: 36px !default;
$datepicker-cell-height: 36px !default;

$datepicker-disabled-cell-color: $gray-300 !default;
$datepicker-disabled-old-new-color: $gray-500 !default;

$datepicker-header-cell-border-radius: $border-radius !default;

$datepicker-active-color: $white !default;
$datepicker-active-background: theme-color("primary") !default;
$datepicker-active-box-shadow: none !default;

$datepicker-range-background: theme-color("primary") !default;
$datepicker-range-cell-focused-background: darken(
  $datepicker-range-background,
  5%
);
$datepicker-range-color: $white !default;
$datepicker-range-highlighted-bg: $gray-200 !default;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white !default;
$datepicker-highlighted-bg: $datepicker-active-background !default;

// Footer

$footer-link-font-size: 0.85rem !default;
$footer-bg: theme-color("secondary") !default;
$footer-color: $gray-600 !default;
$footer-link-color: $gray-600 !default;
$footer-link-hover-color: $gray-700 !default;
$footer-heading-color: $gray-600 !default;
$footer-heading-font-size: $font-size-sm !default;
